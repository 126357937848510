import React from "react"
import { Link } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import Logo from '../../images/lifesync-logo-white.svg'
import TYThumb from '../../images/thankYouThumb.png'


const HeaderThankYou = ({ siteTitleContact }) => (
  <header className="headerThankYou">
    <Container>
      <Row className="boxedNav">
        <Col xs={6}>
          <Link to="/">
            <img src={Logo} alt="LifeSync" width="185" className="logo" title="LifeSync" loading="lazy" />
          </Link>
        </Col>
        <Col xs={6} className="text-right">
          <a href="tel:18003582468" className="topRightPhoneBoxed">CALL US AT 1-800-358-2468</a>
        </Col>
      </Row>
    </Container>
    <Container>
        <Row className="justify-content-center">
            <Col xs={12} lg={10} xl={8}>
                <div className="thankYouWrapper">
                    <div className="topThanks">
                        <Row>
                            <Col xs={12}>  
                                <h1>Thank you for registering!</h1>
                                <p className="lead">Your free needle is on its way to you.</p>
                            </Col>
                        </Row>
                        <Row>                            
                            <Col xs={12} md={{ order: 2, span: 7}} className="topThanksRight">
                                <p className="mediumLead">While you wait, here are a few benefits you and your patient can expect from your LifeSync EMG Needle.</p>
                                <Button as="a" href="https://www.lifesync.com/pdf/LifeSync-EMG-Needle-Datasheet.pdf" target="_blank" rel="noopener noreferrer">Download Now!</Button>
                            </Col>
                            <Col xs={12} md={{ order: 1, span: 5}} className="text-center">
                                <img src={TYThumb} alt="Thank you for registering" className="thanksThumb" />
                            </Col>
                        </Row>
                    </div>
                    <div className="bottomThanks">
                        <Row>
                            <Col xs={12} md={8} lg={9}>
                                <p>Visit the LifeSync Neuro store for a complete selection of neurodiagnostic and neuromonitoring connections.</p>
                            </Col>
                            <Col xs={12} md={4} lg={3}>
                                <a className="btn btn-primary" href="https://rochestersuperstore.com/emg/emg-needles/" target="_blank" rel="noopener noreferrer">Shop Now</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
  </header>
)

export default HeaderThankYou
