import React from "react"

import LayoutThankYou from "../components/ThankYou/layoutThankYou"
import SEO from "../components/seo"

const ThankYouPage = () => (
  <LayoutThankYou className="thankYouLP">
    <SEO title="LifeSync EMG Needles" />
  </LayoutThankYou>
)

export default ThankYouPage
